import React from 'react';
import { Admin, Resource,CustomRoutes } from 'react-admin';
import { Route } from "react-router-dom";
import { firebaseConfig, firebaseApp } from "../firebase"
import {
  FirebaseAuthProvider,
  FirebaseDataProvider
} from 'react-admin-firebase';
import { MenuList, MenuEdit, MenuCreate, MenuShow} from './Menu';
import { HoursList, HoursEdit} from './Hours';
import { AnimationsList, AnimationsEdit} from './Animations';
import { MyLayout } from './MyLayout';

const options = {
  app: firebaseApp,
  persistence: 'session'
}

const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProvider = FirebaseAuthProvider(firebaseConfig, options);

function AdminPage() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={MyLayout}
      basename="/admin"
      title="Admin"
    >
      <Resource name="menu" list={MenuList} show={MenuShow} create={MenuCreate} edit={MenuEdit} />
      <Resource name="hours" list={HoursList} edit={HoursEdit}  />
      <Resource name="animations" list={AnimationsList} edit={AnimationsEdit}  />
    </Admin>
  );
}

export default AdminPage;
