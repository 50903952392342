import React,{useState,useRef} from 'react';
import heyYouLogo from "../../assets/hey_you.png";
import "./About.css";

const About = () => {

    return (
        <div className="about">
            <section className="image-slider-container">
                <div className="image-slider">
                    <div className="about-us-header">
                        <img height="55px" src={heyYouLogo} alt="Hey You!"/>
                        <p className="attention-grabber">
                            Do you have a recurring dream about drowning in a sea of pizza sauce, but you don’t consider it a nightmare?
                        </p>
                    </div>
                </div>
            </section>

            <div className="about-us">
                <p className="our-goal our-goal-first-statement">
                    WELL YOU MY FRIEND, HAVE COME TO THE RIGHT PLACE.
                </p>
                <div className="background-image-two"></div>
                <p className="our-goal">
                    Our Goal at Doughballs is that the world would welcome pizza with caa-razy toppings with open hearts and growling stomachs. But it’s a two way street, and we know that with great pizza, comes great responsibility.
                </p>
                <div className="background-image-three"></div>
                <p className="our-goal">
                We believe that restricting a flakey, buttery, crispy pie crust with just Marinera sauce, pepperoni, sausage and cheese should be illegal. Seriously. Why wouldn’t you want to combine the greatest invention known to man Pizza! with other foods that are darn good too, like macaroni & cheese, pot roast, french fries! catch our drift?
                </p>
                <p className="our-goal">That’s why we focus on three things</p>
                <div className="background-image-four">
                </div>
                <p className="our-goal">
                    Making ridiculously awesome pizza that actually tastes as good as it looks with the freshest ingredients possible. Duh.
                </p>
                <p className="our-goal">
                    Keeping the business crowd satisfied during the day & the professional drinkers satisfied by night. That’s why we’re open 7 days a week, and we're OPEN LATE! because the party never stops. And we want to make sure the thousands of suburban bar-goers are served, sobered up & somewhat coherent on a regular basis.
                </p>
                <p className="our-goal">
                    Making it a priority to give back and have strong ties to the community. Our stores give back to our communities with new donations every month in the form of fundraisers, so you’ll always be eating for a good cause. Begging, bribes & romantic sauce-based poems may increase the size of the donation…
                </p>
                <p className="our-goal">
                    So hit us up or mosey on in and check us out. That’s what we’re all about. If you don’t like it, we don’t care.
                </p>
            </div>

        </div>
    );

}

export default About