import React from 'react';
import {
  Datagrid,
  List,
  Show,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  SelectInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  required,
  ReferenceInput
} from 'react-admin';

export const AnimationsList = (props) => {
  return (
    <List 
      {...props}
    >
      <Datagrid>
        <TextField source='text' />
        <EditButton />
      </Datagrid>
    </List>
  )
};

export const AnimationsEdit = (props) => (
  
  <Edit {...props}>
    <SimpleForm>
        <TextInput source='text' multiline/>
    </SimpleForm>
  </Edit>

);
