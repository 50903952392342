import React,{useState,useRef} from 'react';
import "./Gallery.css";

import bosco from "../../assets/bosco-sticks.jpg";
import mushroom from "../../assets/mushroom.jpg";
import sausage from "../../assets/sausage-pizza.jpg";

const Gallery = () => {

    return (
        <div class="gallery-container">

            <div class="image-slider">
                <p>
                    Gallery
                </p>
            </div>


            <img src={bosco} / >
            <img src={sausage} / >
            <img src={mushroom} / >

        </div>
    );

}

export default Gallery