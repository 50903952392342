import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAPKcvQS0lNb8gBfdE9e_rGtHdN57RwqDk",
  authDomain: "doughballs-626d7.firebaseapp.com",
  projectId: "doughballs-626d7",
  storageBucket: "doughballs-626d7.appspot.com",
  messagingSenderId: "1053465488253",
  appId: "1:1053465488253:web:5a9697824aacba0434fa14",
  measurementId: "G-1ET2EXERVN"
};

let firebaseApp = null;
if (typeof window !== undefined) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
}

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();

export { firebaseApp, firebaseConfig, auth };
export default db;
export {db};
