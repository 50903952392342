import React from "react";

const MenuList = ({ items }) => {

  return (
    <div className="section-center">
      {items.map((item) => {
        const { id, title, image, description, price, comments } = item;

        return (
          <article key={id} className="menu-item">
            {image?
              <img src={image.src} alt={title} className="photo" />:
              null
            }
            <div className="item-info">
              <header>
                <h4>{title}</h4>
                {price ? <h4 className="price">${price}</h4> : "" }
              </header>
              <p className="item-text">{description}</p>
              {comments ? <p className="item-text">{comments}</p>: null}
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default MenuList;
