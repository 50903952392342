import React,{useState,useRef} from 'react';
import "./Footer.css";

export default () => {

    return (
        <div class="footer">
         
            <div class="copy-right-statement">
                <p>©2023 Doughballs Pizzas</p>
            </div>

            <div class="david-pina">
                <a class="davidpina-hyper-link" href="https://davidpina.dev"> Web Design by: Pineapple</a>
            </div>

        </div>
    );

}
