import React,{useState,useRef} from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/logo.png";
import "./Header.css";

export default () => {

    return (
    <div className="header">
        <div className="logo-container">
            <Link to="/"><img className={logo} src={logo}/></Link>
        </div>
        <div className="header-nav-container">
            <ul className="ul-container">
                <li className="nav-options">
                    <Link className="nav-links" to="menu">Menu</Link>
                </li>
                <li className="nav-options">
                    <Link className="nav-links" to="gallery">Gallery</Link>
                </li>
                <li className="nav-options">
                    <Link className="nav-links" to="about">About Us</Link>
                </li>
            </ul>
        </div>
    </div>
    );

}
