import React, {useState,useEffect} from 'react';
import veggiePizza from "../../assets/veggie-pizza.png";
import yelpLogo from "../../assets/yelp.svg";
import doorDashLogo from "../../assets/doordash-logo.png";
import uberEatsLogo from "../../assets/uber_eats_logo.jpg";
import grubHubLogo from "../../assets/grubHub.svg.png";
import map from "../../assets/map.png";
import {db} from "../../firebase"
import "./Home.css";

const Home = () =>{

    const [animationText, setAnimationText] = useState([]);
    const [storeHours, setStoreHours] = useState([]);
    
    const getAnimationText = async () =>{
        
        const storeHoursDoc = db.collection('hours').orderBy("order","asc").get().then(querySnapshot=>{
            let hours = [];
            
            querySnapshot.forEach((doc) => {
                hours.push(doc.data());
            });
            
            setStoreHours(hours);
        })

        const animationsDoc = db.collection('animations').get().then(querySnapshot=>{
            
                let text = [];

                querySnapshot.forEach((doc) => {
                    text.push(doc.data());
                });
                
                setAnimationText(text);
                console.log(text)
        });

        return [];

    }
    
    useEffect(() => {
        if(storeHours.length==0 && animationText.length==0){
            getAnimationText();
        }
    },[])
    console.log(storeHours,animationText)
    
    return(
        <div className="home">
        
        <div className="image-slider">
            <div className="pizza-box">
                <div className="home-intro home-intro-text">
                    {animationText.length > 0 ? (

                        animationText.map((text,index)=>
                            <p key={index} className={"slide "+"slide-"+index}>{text.text}</p>
                        )

                    ):
                    <div>
                        <p className="slide slide-1">Yeah we're still open! Take out and Delivery only</p>
                        <p className="slide slide-2">Slices available all day</p>
                        <p className="slide slide-3">Try our famous Hog Heaven</p>
                    </div>
                }
             
                </div>
                
                <div className="home-intro">
                    <img className="pizza-animation" src={veggiePizza} alt="pizza"/>
                </div>
            </div>
            </div>
            <div className="orderOnlineMenuContainer" style={{textAlign:"center",fontSize:"",color:"#fff",padding:'10px'}}>
                <h2>
                    Doughballs is now taken over by Ballydoyle Irish Pub
                </h2>

                <p style={{paddingBottom:'10px'}}>
                    click link and order from both Ballydoyle and Doughballs pizza menu 
                </p>

                <a className="orderOnlineMenu" href="https://toasttab.com/ballydoyleaurora/v2/online-order#!/">
                    order online
                </a>

                <a className="orderOnlineMenu doordash" href="https://doordash.com/store/ballydoyle-irish-pub-aurora-874363/">
                    doordash
                </a>

                <a className="orderOnlineMenu uber" href="https://ubereats.com/store/doughballs-pizza-palace/Ca86YVpvTNqmHrvIVA-8zA?diningMode=DELIVERY&ps=1">
                    uber eats
                </a>

                <a className="orderOnlineMenu grubhub" href="https://grubhub.com/restaurant/doughballs-pizza-40-w-new-york-st-aurora/1962649?utm_source=grubhub_androidapp&utm_medium=content_owned&utm_campaign=menushare&utm_content=share-link">
                    GrubHub
                </a>

            </div>

            <section className="other">

                <p className="were-in-aurora-title">We're In Downtown Aurora, IL</p>

                <div id="map">
                    <a className="google-map" href="https://goo.gl/maps/EowHzCQXwgG3pXYTA"><img width="280" src={map} alt="Google Map of 40 W New York St, Aurora, IL 60506"/></a>
                        <p className="location-in-text">
                            40 W New York St, Aurora, IL 60506
                        </p>
                </div>

                <div className="contact-info">
          
                        <a className="phone-hyper-link" href="tel:630-844-5205">
                        <p className="location-in-text phone-number">Give Us A Call</p>
                        <p className="location-in-text phone-number">630-844-5205</p>
                        </a>
                        <div className="our-hours-in-text">
                            <h3 className="hours are-we-open">Are We Open? Probably.</h3>
                   {storeHours.length>0?(
                       <div className="resturant-hours">{
                           storeHours.map((hours,key)=>
                                <p key={key} className="hours">{hours.day}: {hours.hours}</p>
                            )
                        }
                        </div>
                   ):(      <div className="resturant-hours">
                                <p className="hours">Mon: Closed</p>
                                <p className="hours">Tue: Closed</p>
                                <p className="hours">Wed: 3 P.M - 10 P.M</p>
                                <p className="hours">Thu: 3 P.M - 10 P.M</p>
                                <p className="hours">Fri: 3 P.M - 10 P.M</p>
                                <p className="hours">Sat: 3 P.M - 10 P.M</p>
                                <p className="hours">Sun: 3 P.M - 10 P.M</p>
                            </div>
                        )}
                        </div>
                </div>

                <div className="user-options">
                    <div className="order-online-container">
                        <p className="order-title">Order Online</p>
                        {/* <a className="grubhub-image" href="https://www.grubhub.com/restaurant/doughballs-pizza-40-w-new-york-st-aurora/320419"><img className="online-order-image" src={grubHubLogo} alt="GrubHub Link" height="50px"/></a> */}
                        {/* <a href="https://www.doordash.com/store/doughballs-pizza-palace-aurora-170161/"><img className="online-order-image" src={doorDashLogo} alt="Doordash Image"/></a> */}
                        {/* <a href="https://www.ubereats.com/en-US/chicago/food-delivery/doughballs-pizza-palace/Ca86YVpvTNqmHrvIVA-8zA/"><img className="online-order-image" src={uberEatsLogo} alt="Uber Eats Link" height="200px"/></a> */}

                        <a className="orderOnlineMenu" href="https://www.toasttab.com/ballydoyleaurora/v2/online-order#!/">
                            order online
                        </a>
                    </div>
                    <div className="reviews">
                        <p className="review-title">Give us a review.</p>
                        <a href="https://www.yelp.com/biz/doughballs-pizza-palace-aurora"><img src={yelpLogo} alt="yelp link"/></a>
                    </div>
                </div>
            </section>

        </div>  
            
    )
}

export default Home