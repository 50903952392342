import React from 'react';
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
  ImageField,
  ImageInput,
  SelectInput,
  required,
  ReferenceInput
} from 'react-admin';

export const MenuShow = (props) => (
  
  <Show {...props}>
      <Datagrid>
        <TextField source='title' />
        <TextField source='order' />
        <ImageField source="image.src" title="title" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
  </Show>
);

export const MenuList = (props) => {
  return (
    <List 
      {...props}
      sort={{ field: 'order', order: 'ASC' }}
    >
      <Datagrid>
        <TextField source='order' />
        <TextField source='title' />
        <TextField source='category' />
        <ImageField source="image.src" title="title" />
        <EditButton />
      </Datagrid>
    </List>
  )
};
export const MenuCreate = (props) => (
  <Create {...props} >
    <SimpleForm>
      
        <TextInput source='title' multiline/>
        <TextInput source='order' defaultValue="0"/>
        <TextInput source='description' fullWidth multiline/>
        <TextInput source='comments' fullWidth multiline/>
        <TextInput source='price' />
        <SelectInput source="category" validate={required()} resettable choices={[
            { id: 'pizza', name: 'pizza' },
            { id: 'calzones', name: 'calzones' },
            { id: 'pasta', name: 'pasta' },
            { id: 'salads', name: 'salads' },
            { id: 'sandwiches', name: 'sandwiches' },
            { id: 'appetizers', name: 'appetizers' },
        ]} />

        <ImageInput source="image" label="image" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

    </SimpleForm>
  </Create>
);

export const MenuEdit = (props) => (
  


  <Edit {...props}>
    <SimpleForm>
        <TextInput source='title' multiline/>
        <TextInput source='order' />
        <TextInput source='description' fullWidth multiline/>
        <TextInput source='comments' fullWidth multiline/>
        <TextInput source='price' />
        <SelectInput source="category" validate={required()} resettable choices={[
            { id: 'pizza', name: 'pizza' },
            { id: 'calzones', name: 'calzones' },
            { id: 'pasta', name: 'pasta' },
            { id: 'salad', name: 'salad' },
            { id: 'sandwiches', name: 'sandwiches' },
        ]} />

        <ImageInput source="image" label="image" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

    </SimpleForm>
  </Edit>
);
