import React from 'react';
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
  ImageField,
  ImageInput,
  SelectInput,
  required,
  ReferenceInput
} from 'react-admin';

export const HoursList = (props) => {
  return (
    <List 
      {...props}
      sort={{ field: 'order', order: 'ASC' }}
    >
      <Datagrid>
        <TextField source='day' />
        <TextField source='hours' />
        <EditButton />
      </Datagrid>
    </List>
  )
};

export const HoursEdit = (props) => (
  
  <Edit {...props}>
    <SimpleForm>
        <TextField  style={{ fontSize: "3em"}} source='day' />
        <TextInput source='hours' />
    </SimpleForm>
  </Edit>

);
