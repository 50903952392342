import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, {useContext,useState,useRef} from 'react';
import { CustomRoutes } from 'react-admin';
import { MenuList, MenuEdit, MenuCreate, MenuShow} from '../../Admin/Menu';
import AdminPage from '../../Admin/Admin';
import Home from "../../pages/Home/Home";
import Gallery from "../../pages/Gallery/Gallery";
import Menu from "../../pages/Menu/Menu";
import About from "../../pages/About/About";
import Login from "../../pages/Login/Login";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './App.css';

export default function App () {
    
    const renderAppContent = () => {
        
        return (
        <BrowserRouter>
            <div className="app-wrapper">
                <Header/>
                <Routes>
                    <Route path="/" element={<Home />} exact />
                    <Route path="gallery" element={<Gallery />}  />
                    <Route path="menu" element={<Menu />} />
                    <Route path="about" element={<About />} />
                    <Route path="login" element={<Login />} />
                    <Route path="admin/*" element={<AdminPage />}/>
             
                </Routes>
                <Footer/>
            </div>

        </BrowserRouter>
        )
    };

    return(
        <div className="app-wrapper" 
        style={{height: "100vh"}}
        tabIndex="0">
            {renderAppContent()}
        </div>
    )

}