import React ,{useState,useEffect} from "react";
import MenuList from "../../components/MenuList/MenuList";
import Categories from "../../components/Categories/Categories";
import "./Menu.css"
import {db} from "../../firebase"

const Menu = () => {

    const [allCategories, setAllCategories] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [selectedMenuItems, setSelectedMenuItems] = useState([]);
    const [activeCategory, setActiveCategory] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    
    const filterItems = (category) => {

        setActiveCategory(category);
        if (category === "all") {
            setSelectedMenuItems(menuItems);
            return;
        }
        const newItems = menuItems.filter((item) => item.category === category);
        setSelectedMenuItems(newItems);
        
    };

    const getMenuItems = async () =>{
        
        setIsLoading(false)

        const menuRef = db.collection("menu").orderBy("order", "asc");

        const doc = await db.collection('categories').doc("9RGi1V8LKOLMkE1bZnhS").get().then(data=>{
            
                    
            menuRef.get().then((querySnapshot) => {
                
                let categories = [];
                let menuItems = [];

                querySnapshot.forEach((doc) => {
                    menuItems.push(doc.data());
                });
                
                data.data().names.forEach(category=>{
                    categories.push(category);
                })
                
                setAllCategories(categories);
                setMenuItems(menuItems);
            });
            
            
        });

        return doc

    }
    
    useEffect(() => {
        if(menuItems.length > 1){
            filterItems("pizza");
        }
    },[menuItems])

    useEffect(() => {

        if(menuItems.length == 0 && allCategories.length == 0 && isLoading){
            getMenuItems()
        }
      }, [isLoading,allCategories]);

  return (
    <div className="menu">

        <section className="image-slider-container">
            <div className="image-slider">
                <p>
                    Menu
                </p>
            </div>
        </section>

        {allCategories.length>0 ?
        <Categories
            categories={allCategories}
            activeCategory={activeCategory}
            filterItems={filterItems} 
        />:
            <h2 className="loadingHeader">
                LOADING...
            </h2>
        }

        {menuItems.length>0 && !isLoading ? <MenuList items={selectedMenuItems} />:"Loading"}

    </div>
  );
};

export default Menu;